@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.zoom:hover img {
  transform: scale(1.1);
}

.cls-1 {
  fill-rule: evenodd;
}

/* Card Component Styles */
.card {
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  height: 250px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  border: 2px solid rgba(7, 7, 7, 0.12);
  font-size: 16px;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.card:hover {
  height: 270px;
}

.card:hover .icon {
  background-position: -120px;
  transition: all 0.3s ease;
}

.icon {
  margin: 0 auto;
  width: 100%;
  height: 80px;
  max-width: 80px;
  /* background: linear-gradient(90deg, #6366f1 0%, #4F46E5 40%, #ffffff 100%); */
  background: linear-gradient(90deg, #4F46E5 0%, #ffffff 100%);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transition: all 0.8s ease;
  background-position: 0px;
  background-size: 200px;
}

.card:hover .icon {
  /* Add the transition effect for the icon itself */
  color: black; /* Change the icon color to black on hover */
}

.card .title {
  width: 100%;
  margin: 0;
  text-align: center;
  margin-top: 30px;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.card .text {
  width: 80%;
  margin: 0 auto;
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
  color: white;
  font-weight: 200;
  letter-spacing: 2px;
  opacity: 0;
  max-height: 0;
  transition: all 0.3s ease;
}

.card:hover .text {
  transition: all 0.3s ease;
  opacity: 1;
  max-height: 40px;
}

/* Add any additional hover effects and transitions as needed */
