.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Basic styles for Contentful Rich Text content */
.contentful-rich-text {
  /* General text styles */
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.contentful-rich-text h1,
.contentful-rich-text h2,
.contentful-rich-text h3,
.contentful-rich-text h4,
.contentful-rich-text h5,
.contentful-rich-text h6 {
  /* Heading styles */
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.contentful-rich-text p {
  /* Paragraph styles */
  margin-top: 0;
  margin-bottom: 1em;
}

.contentful-rich-text a {
  color: rgb(79 70 229);
  text-decoration: underline;
}

.contentful-rich-text a:hover {
  color: rgb(55 48 163);
}

.contentful-rich-text img {
  margin: 0 auto;
  margin-bottom: 1em;
}

.contentful-rich-text iframe {
  margin-bottom: 1em;
}

.contentful-rich-text ul,
.contentful-rich-text ol {
  /* List styles */
  margin-top: 0;
  margin-bottom: 1em;
  padding-left: 2em;
}

.contentful-rich-text ul {
  list-style-type: disc; /* Default style for unordered lists */
  padding-left: 40px;   /* Adjust padding to ensure bullets are visible */
}

.contentful-rich-text ol {
  list-style-type: decimal; /* Default style for ordered lists */
  padding-left: 40px;       /* Adjust padding to ensure numbers are visible */
}

.contentful-rich-text li {
  margin-bottom: 0.5em; /* Spacing between list items */
}

.contentful-rich-text hr {
  padding: 10px 0;
}

.contentful-rich-text img {
  /* Image styles */
  max-width: 100%;
  height: auto;
}

.contentful-rich-text blockquote {
  /* Blockquote styles */
  border-left: 4px solid #eee;
  padding-left: 1em;
  margin-left: 0;
  margin-right: 0;
  color: #666;
}

.news-content a {
  color: rgb(79 70 229);
  text-decoration: underline;
}

.news-content a:hover {
  color: #1e40af;
  text-decoration: underline;
}

.news-content p {
  margin-bottom: 10px;
}

.news-container > * {
    margin-bottom: 10px; 
}

.news-content img {
  border-radius: 0.375rem;
}

.news-content figure {
  margin: 30px 0;
}

.news-content figure img {
  margin: 0 auto;
}

.news-content figure figcaption {
  margin: 0 auto;
  text-align: center;
}

.news-content .twitter-tweet {
  margin: 30px auto !important;
}

.news-content p img {
  margin: 0 auto;
}

.news-content table {
  margin: 20px 0;
}

.loading-spinner-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6); /* semi-transparent white background */
}

.loading-spinner-overlay::before {
  content: '';
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #5052c4; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.tradingview-widget-copyright {
  display: none;
}

.ts-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

/* Hover effects */
.ts-card:hover {
  transform: scale(1.03); /* Slightly scales up the card */
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04); /* Increases shadow for depth */
  background-color: #f3f4f6; /* Lightens the background color */
}

.black-gradient-left-to-right {
  background: linear-gradient(to right, black, rgb(67, 56, 202));
}

.marginZero {
  margin: 0 auto
}

.PhoneInputInput {
  border-radius: 0.375rem !important;
  border-color: rgb(209 213 219) !important;
}

.html-embed .tradingview-widget-container iframe {
  border-radius: 0.375rem !important;
  margin: 0 auto !important;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 1) 0px 2px 4px 0px;
}

.html-embed {
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  margin: 1em 0 !important;
  overflow: hidden;
}

.html-embed .tradingview-widget-container {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 767px) {
  .html-embed div {
    width: 100% !important;
  }
}

.stock-widget-container iframe {
  transform: scale(0.7);
  transform-origin: top left;
  border: 0;
  overflow: hidden;
}

.stock-widget-container {
  overflow: hidden;
  width: 50%;
  height: 230px;
}